
import { Component, Vue } from "vue-property-decorator";
import { Foundation } from "@/utils/Foundation";
import API_MerchantList from "@/utils/API/merchant/MerchantList";
import API_FundRecord from "@/utils/API/finance/FundRecord";
import { _rechargeFundRecord } from "@/utils/pageConfig/finance/_rechargeFundRecord";
import recharge from "../../merchant/components/recharge.vue";

@Component({
    components: {
        recharge,
    },
})
export default class RechargeRecord extends Vue {
    pageConfig: any = _rechargeFundRecord;
    tableData: any = [];
    showTable: boolean = false;
    total: number = 0;
    params: any = {
        current: 1,
        size: 20,
    };
    exportForm: any = {
        type: {
            tag: "ADMIN_FUND_RECORD_EXPORT",
            url: "/core/api/v1/admin/c/export/exec/fund/record",
        },
        fileName: "充值记录",
    };

    async mounted() {
        // 搜索框商户列表
        let options: any = [];
        let res: any = await API_MerchantList.getAllMerchant();
        res.map((e: any) => {
            options.push({ label: e.coName, value: e.id });
        });

        this.pageConfig.search[0].options = options;
    }

    /*获取表格数据*/
    async getTableData() {
        let res: any = await API_FundRecord.getFundRecord(this.params);
        this.tableData =
            (res.records &&
                res.records.length > 0 &&
                res.records.map((e: any) => {
                    e._scene = Foundation.findInArray(
                        _rechargeFundRecord.sceneType,
                        "value",
                        e.scene
                    ).label;
                    e._amount = Foundation.toYUAN(e.amount);
                    e._currentBalance = Foundation.toYUAN(e.currentBalance);
                    e._recordTime = Foundation.dateFormat(e.recordTime);
                    e._confirmFlag = ["否", "是"][e.confirmFlag] || "";
                    e._confirmAmount = Foundation.toYUAN(e.confirmAmount);
                    return e;
                })) ||
            [];
        this.total = res.total;
    }

    /*底部分页切换*/
    currentChange(pageNumber: number) {
        this.params.current = pageNumber;
        this.getTableData();
    }

    /*底部分页条数切换*/
    sizeChange(sizeNumber: number) {
        this.params.size = sizeNumber;
        this.getTableData();
    }

    /*查询*/
    handleSearch() {
        let query: any = Foundation.resetParams(
            (this.$refs.searchForm as any).form,
            true
        );

        query = Foundation.resetDate(query);
        if (!query.merchantId) {
            this.$message.error("请先选择商户！");
            return;
        }
        this.showTable = true;
        this.exportForm.condition = query;
        this.params = {
            current: 1,
            size: 20,
            ...query,
        };
        this.getTableData();
    }

    summaryMethod(param: any) {
        const { data } = param;
        let sums: any = ["", "合计：", "**", "", "", "", "", "", "**"];
        const amountTotal = data.map((item: any) => Number(item.amount));
        const confirmAmountTotal = data.map((item: any) =>
            Number(item.confirmAmount)
        );
        sums[2] = Foundation.toYUAN(
            amountTotal.reduce((prev: any, curr: any) => {
                return prev + curr;
            }, 0)
        );
        sums[8] = Foundation.toYUAN(
            confirmAmountTotal.reduce((prev: any, curr: any) => {
                return prev + curr;
            }, 0)
        );
        return sums;
    }

    /*编辑*/
    handleEdit(data: any) {
        (this.$refs.recharge as any).show(data);
    }

    /*导出*/
    handleExport() {
        (this.$refs.exports as any).show(this.exportForm);
    }
}
