export const _rechargeFundRecord = {
    //充值筛选
    search: [
        {
            type: 'el-select',
            label: '商户：',
            value: 'merchantId',
            props: {
                filterable: true,
                placeholder: '请选择商户'
            },
            options: []
        },
        {
            type: 'el-date-picker',
            label: '充值时间：',
            value: 'date',
            props: {
                type: 'daterange',
                'range-separator': '至',
                'start-placeholder': '开始日期',
                'end-placeholder': '结束日期',
                'default-time': ['00:00:00', '23:59:59'],
                'value-format': "timestamp"
            }
        },
    ],
    //充值列表
    table: [
        {
            type: 'index',
            label: '序号',
            width: '50'
        },
        {
            prop: 'merchantName',
            label: '商户名称'
        },
        {
            prop: '_amount',
            label: '操作金额(元)'
        },
        {
            prop: '_currentBalance',
            label: '本次操作前余额(元)'
        },
        {
            prop: '_recordTime',
            label: '充值时间'
        },
        {
            prop: '_scene',
            label: '操作场景'
        },
        {
            prop: 'remark',
            label: '客户备注'
        },
        {
            prop: '_confirmFlag',
            label: '微信商户平台是否入账'
        },
        {
            prop: '_confirmAmount',
            label: '备注入账金额'
        },
        {
            prop: 'operateRemark',
            label: '运营备注'
        },
        {
            label: '操作',
            self: {
                body: 'operation'
            }
        }
    ],
    //退款筛选
    reFundSearch: [
        {
            type: 'el-select',
            label: '商户：',
            value: 'merchantId',
            props: {
                filterable: true,
                placeholder: '请选择商户'
            },
            options: []
        },
        {
            type: 'el-date-picker',
            label: '退款时间：',
            value: 'date',
            props: {
                type: 'daterange',
                'range-separator': '至',
                'start-placeholder': '开始日期',
                'end-placeholder': '结束日期',
                'default-time': ['00:00:00', '23:59:59'],
                'value-format': "timestamp"
            }
        },
    ],
    //退款列表
    reFundTable: [
        {
            type: 'index',
            label: '序号',
            width: '50'
        },
        {
            prop: 'merchantName',
            label: '商户名称'
        },
        {
            prop: '_amount',
            label: '操作退款金额(元)'
        },
        {
            prop: '_currentBalance',
            label: '本次操作前余额(元)'
        },
        {
            prop: '_remainAmount',
            label: '账户余额(元)'
        },
        {
            prop: '_recordTime',
            label: '退款时间'
        },
        {
            prop: '_scene',
            label: '操作场景'
        },
        {
            prop: 'remark',
            label: '备注'
        },
        {
            label: '操作',
            self: {
                body: 'operation'
            }
        }
    ],
    sceneType: [
        {
            label: '默认',
            value: '0',
        },
        {
            label: '充值',
            value: '1',
        },
        {
            label: '退款',
            value: '2',
        },
        {
            label: '红包',
            value: '3',
        },
        {
            label: '红包退款',
            value: '4',
        },
        {
            label: '红包退款',
            value: '4',
        },
        {
            label: '商户退款',
            value: '5',
        }
    ]
};

